<template>
  <v-container style="width: 100%;">

  <v-dialog v-model="dialogForm" max-width="600">
      <v-card class="elevation-12" style="padding: 15px;">
      <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-title class="d-flex justify-center font-weight-bold">
        Formulario de Postulación
      </v-card-title>

        <v-text-field
        v-model="dni"
        :rules="dniRules"
        :counter="8"
        label="DNI"
        required
        ></v-text-field>

        <v-text-field
          v-model="names"
          :counter="100"
          :rules="stringRules"
          label="Nombre"
          required
        ></v-text-field>

        <v-text-field
          v-model="lastNameFather"
          :counter="100"
          :rules="stringRules"
          label="Apellido Paterno"
          required
        ></v-text-field>

        <v-text-field
          v-model="lastNameMother"
          :counter="100"
          :rules="stringRules"
          label="Apellido Materno"
          required
        ></v-text-field>

        <v-menu 
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :rules="dateRules"
              label="Fecha de nacimiento"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date" 
            :active-picker.sync="activePicker"
            max="2001-12-30"
            min="1973-01-01"
            @change="save"
          ></v-date-picker>
        </v-menu>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>

        <v-text-field
          type="number"
          v-model.number="cellphone"
          :counter="9"
          :rules="cellphoneRules"
          label="Celular"
          required
        ></v-text-field>

        <v-text-field         
          v-model="phone"
          :counter="9"
          :rules="phoneRules"
          label="Télefono (OPCIONAL)"
        ></v-text-field>

        <v-text-field
          v-model="education"
          :counter="100"
          :rules="stringRules"
          label="Educación"
          required
        ></v-text-field>

        <v-file-input
          v-model="cv"
          show-size
          counter
          chips
          label="CV"
          :rules="cvRules"
          required
        ></v-file-input>

        <div class="d-flex justify-center">
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate"
        >
          ENVIAR
        </v-btn>
      </div>
      </v-form>
      </v-card>
  </v-dialog>

  <!--Dialogo Cronograma(Matematica-4P)-->
  <v-dialog v-model="dialogSchedule" max-width="900">
    <v-card>
      <v-card-title class="d-flex justify-center font-weight-bold">Cronograma del proceso</v-card-title>
      <v-card-text>
        <v-container>
          <table class="table">
            <thead>
              <tr>
                <th class="text-center">Actividad</th>
                <th class="text-center">Fechas</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="activity in activities" :key="activity.activity">
                <td><strong> {{ activity.activity }} </strong></td>
                <td class="text-center"> <strong>{{ activity.date }} </strong></td>
              </tr>
            </tbody>
          </table>
          <div class="conditions">  
          <b class="large-font">CONDICIONES DEL TRABAJO</b>
          <ul>
            <li><strong>Lunes a sábado: 8:00 a.m. a 2:00 p.m.</strong></li>
            <li><strong>Se brindará almuerzo a todo el personal.</strong></li>
          </ul>
          <br>
          <b class="large-font">REMUNERACIÓN</b>
          <ul>
            <li><strong>Codificadores: S/.150.00 por día de codificación</strong></li>
          </ul>
          <br>
          <b class="large-font">Lugar de la prueba escrita: Se le comunicará al postulante preseleccionado.</b>
          
          
          
          
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!--Dialogo Cronograma(Matematica-2S)-->
  <v-dialog v-model="dialogSchedule1" max-width="900">
    <v-card>
      <v-card-title class="d-flex justify-center font-weight-bold">Cronograma del proceso</v-card-title>
      <v-card-text>
        <v-container>
          <table class="table">
            <thead>
              <tr>
                <th class="text-center">Actividad</th>
                <th class="text-center">Fechas</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="activity1 in activities1" :key="activity1.activity1">
                <td><strong> {{ activity1.activity1 }} </strong></td>
                <td class="text-center"> <strong>{{ activity1.date1 }} </strong></td>
              </tr>
            </tbody>
          </table>
          <div class="conditions">  
          <b class="large-font">CONDICIONES DEL TRABAJO</b>
          <ul>
            <li><strong>Lunes a sábado: 8:00 a.m. a 2:00 p.m.</strong></li>
            <li><strong>Se brindará almuerzo a todo el personal.</strong></li>
          </ul>
          <br>
          <b class="large-font">REMUNERACIÓN</b>
          <ul>
            <li><strong>Codificadores: S/.150.00 por día de codificación</strong></li>
          </ul>
          <br>
          <b class="large-font">Lugar de la prueba escrita: Se le comunicará al postulante preseleccionado.</b>
          
          
          
          
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!--Dialogo Cronograma(Lectura)-->
  <v-dialog v-model="dialogSchedule2" max-width="900">
    <v-card>
      <v-card-title class="d-flex justify-center font-weight-bold">Cronograma del proceso</v-card-title>
      <v-card-text>
        <v-container>
          <table class="table">
            <thead>
              <tr>
                <th class="text-center">Actividad</th>
                <th class="text-center">Fechas</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="activity2 in activities2" :key="activity2.activity2">
                <td> <strong>{{ activity2.activity2 }} </strong></td>
                <td class="text-center"> <strong>{{ activity2.date2}}</strong></td>
              </tr>
            </tbody>
          </table>
          <div class="conditions">  
          <b class="large-font">CONDICIONES DEL TRABAJO</b>
          <ul>
            <li><strong>Lunes a sábado: 8:00 a.m. a 2:00 p.m.</strong></li>
            <li><strong>Se brindará almuerzo a todo el personal.</strong></li>
          </ul>
          <br>
          <b class="large-font">REMUNERACIÓN</b>
          <ul>
            <li><strong>Codificadores: S/.150.00 por día de codificación</strong></li>
          </ul>
          <br>
          <b class="large-font">Lugar de la prueba escrita: Se le comunicará al postulante preseleccionado.</b>
          
          
          
          
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!--Dialogo Cronograma(Ciencia y Tecnologia)-->
  <v-dialog v-model="dialogSchedule3" max-width="900">
    <v-card>
      <v-card-title class="d-flex justify-center font-weight-bold">Cronograma del proceso</v-card-title>
      <v-card-text>
        <v-container>
          <table class="table">
            <thead>
              <tr>
                <th class="text-center">Actividad</th>
                <th class="text-center">Fechas</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="activity3 in activities3" :key="activity3.activity3">
                <td><strong>{{ activity3.activity3 }}</strong></td>
                <td class="text-center"><strong>{{ activity3.date3}}</strong></td>
              </tr>
            </tbody>
          </table>
          <div class="conditions">  
          <b class="large-font">CONDICIONES DEL TRABAJO</b>
          <ul>
            <li><strong>Lunes a sábado: 8:00 a.m. a 2:00 p.m.</strong></li>
            <li><strong>Se brindará almuerzo a todo el personal.</strong></li>
          </ul>
          <br>
          <b class="large-font">REMUNERACIÓN</b>
          <ul>
            <li><strong>Codificadores: S/.150.00 por día de codificación</strong></li>
          </ul>
          <br>
          <b class="large-font">Lugar de la prueba escrita: Se le comunicará al postulante preseleccionado.</b>
          
          
          
          
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!--Dialogo Cronograma(Ciencia Sociales)-->
  <v-dialog v-model="dialogSchedule4" max-width="900">
    <v-card>
      <v-card-title class="d-flex justify-center font-weight-bold">Cronograma del proceso</v-card-title>
      <v-card-text>
        <v-container>
          <table class="table">
            <thead>
              <tr>
                <th class="text-center">Actividad</th>
                <th class="text-center">Fechas</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="activity4 in activities4" :key="activity4.activity4">
                <td><strong>{{ activity4.activity4 }}</strong></td>
                <td class="text-center"><strong>{{ activity4.date4}}</strong></td>
              </tr>
            </tbody>
          </table>
          <div class="conditions">  
          <b class="large-font">CONDICIONES DEL TRABAJO</b>
          <ul>
            <li><strong>Lunes a sábado: 8:00 a.m. a 2:00 p.m.</strong></li>
            <li><strong>Se brindará almuerzo a todo el personal.</strong></li>
          </ul>
          <br>
          <b class="large-font">REMUNERACIÓN</b>
          <ul>
            <li><strong>Codificadores: S/.150.00 por día de codificación</strong></li>
          </ul>
          <br>
          <b class="large-font">Lugar de la prueba escrita: Se le comunicará al postulante preseleccionado.</b>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!--Perfil Matematica 4P-->
  <div class="elevation-12" style="padding: 15px;">
    <v-btn class="custom-button-det" block  @click="showInfoMate4P = !showInfoMate4P" >
      <div class="left-text">Codificadores de Matemática - 4to de primaria</div>
      <div class="right-text">Detalles</div>
    </v-btn>
    
    <div v-if="showInfoMate4P">
      <br>
      <b>Requisitos:</b>
      <ul>
        <li>Título profesional, licenciatura o bachiller en educación primaria o en educación
            con mención/especialidad en Matemática. </li>
        <li>Egresado o estudiante del último año
            de estas carreras o de una disciplina afín al área de Matemática.</li>
        <li>Experiencia docente en algún grado de primaria en el área de
            Matemática, o en jefatura de práctica en universidades en las carreras
            profesionales señaladas en el punto anterior (deseable). </li>
        <li>Experiencia previa en procesos similares de codificación de pruebas
            estandarizadas de Matemática (deseable)</li>
        <li>Disposición a tiempo completo durante las fechas de capacitación y codificación
            en los horarios estipulados. </li>
        <li>Manejo solvente del área de Matemática (evaluación escrita).</li>
        <li>Capacidad de trabajo en equipo.</li>
        <li>Manejo de Office. </li>
      </ul>
      <br>
      <b>Cronograma:</b>
      <div>
        <v-btn class="mr-4" @click="openSchedule" style="background-color: gainsboro;">Visualizar</v-btn>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-btn class="mr-4" color="success" @click="openDialog('Codificador de Matemática - 4to de primaria')">Postular</v-btn>
      </div>
    </div>
  </div>

    <!--Perfil Matematica 2S-->
  <div class="elevation-12" style="padding: 15px;">
    <v-btn class="custom-button-det" block  @click="showInfoMate2S = !showInfoMate2S" >
      <div class="left-text">Codificadores de Matemática - 2do de secundaria</div>
      <div class="right-text">Detalles</div>
    </v-btn>
    
    <div v-if="showInfoMate2S">
      <br>
      <b>Requisitos:</b>
      <ul>
        <li>Título profesional, licenciatura o bachiller en educación secundaria o en educación
            con mención/especialidad en Matemática. </li>
        <li>Egresado o estudiante del último año
            de estas carreras o de una disciplina afín al área de Matemática.</li>
        <li>Experiencia docente en algún grado de secundaria en el área de
            Matemática, o en jefatura de práctica en universidades en las carreras
            profesionales señaladas en el punto anterior (deseable). </li>
        <li>Experiencia previa en procesos similares de codificación de pruebas
            estandarizadas de Matemática (deseable)</li>
        <li>Disposición a tiempo completo durante las fechas de capacitación y codificación
            en los horarios estipulados. </li>
        <li>Manejo solvente del área de Matemática (evaluación escrita).</li>
        <li>Capacidad de trabajo en equipo.</li>
        <li>Manejo de Office. </li>
      </ul>
      <br>
      <b>Cronograma:</b>
      <div>
        <v-btn class="mr-4" @click="openSchedule1" style="background-color: gainsboro;">Visualizar</v-btn>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-btn class="mr-4" color="success" @click="openDialog('Codificador de Matemática')">Postular</v-btn>
      </div>
    </div>
  </div>

  <!--Perfil Lectura 4P-->
  <div class="elevation-12" style="padding: 15px;">
    <v-btn class="custom-button-det" block  @click="showInfoLec4P = !showInfoLec4P" >
      <div class="left-text">Codificadores de Lectura - 4to de primaria</div>
      <div class="right-text">Detalles</div>
    </v-btn>
    
    <div v-if="showInfoLec4P">
      <br>
      <b>Requisitos:</b>
      <ul>
        <li> Título profesional o licenciatura o bachiller en educación primaria (con mención/especialidad en Lengua o en Literatura). O profesionales con licenciatura o bachiller en Lingüística, Literatura, Comunicación, Psicología o afines. </li>
        <li>Egresados o estudiantes del último año de estas carreras.</li>
        <li>Experiencia docente en educación básica o superior del área de Comunicación o en corrección de estilo o en edición de textos o en traducción (deseable).</li>
        <li>Experiencia previa en procesos de codificación de pruebas estandarizadas de lectura y/o de escritura (deseable). </li>
        <li>Disposición a tiempo completo durante las fechas de capacitación y codificación en los horarios estipulados</li>
        <li>Manejo disciplinar solvente (evaluación escrita).</li>
        <li>Capacidad de trabajo en equipo.</li>
        <li>Manejo de Office.</li>
      </ul>
      <br>
      <b>Cronograma:</b>
      <div>
        <v-btn class="mr-4" @click="openSchedule2" style="background-color: gainsboro;">Visualizar</v-btn>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-btn class="mr-4" color="success" @click="openDialog('Codificador de Lectura - 4to de primaria')">Postular</v-btn>
      </div>
    </div>
  </div>

  <!--Perfil Lectura 2S-->
  <div class="elevation-12" style="padding: 15px;">
    <v-btn class="custom-button-det" block  @click="showInfoLec2S = !showInfoLec2S" >
      <div class="left-text">Codificadores de Lectura - 2do de secundaria</div>
      <div class="right-text">Detalles</div>
    </v-btn>
    
    <div v-if="showInfoLec2S">
      <br>
      <b>Requisitos:</b>
      <ul>
        <li> Título profesional o licenciatura o bachiller en educación secundaria (con mención/especialidad en Lengua o en Literatura). O profesionales con licenciatura o bachiller en Lingüística, Literatura, Comunicación, Psicología o afines. </li>
        <li>Egresados o estudiantes del último año de estas carreras.</li>
        <li>Experiencia docente en educación básica o superior del área de Comunicación o en corrección de estilo o en edición de textos o en traducción (deseable).</li>
        <li>Experiencia previa en procesos de codificación de pruebas estandarizadas de lectura y/o de escritura (deseable). </li>
        <li>Disposición a tiempo completo durante las fechas de capacitación y codificación en los horarios estipulados</li>
        <li>Manejo disciplinar solvente (evaluación escrita).</li>
        <li>Capacidad de trabajo en equipo.</li>
        <li>Manejo de Office.</li>
      </ul>
      <br>
      <b>Cronograma:</b>
      <div>
        <v-btn class="mr-4" @click="openSchedule2" style="background-color: gainsboro;">Visualizar</v-btn>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-btn class="mr-4" color="success" @click="openDialog('Codificador de Lectura - 2do de secundaria')">Postular</v-btn>
      </div>
    </div>
  </div>

  <!--Perfil Ciencia y Tecnologia-->
  <div class="elevation-12" style="padding: 15px;">
    <v-btn class="custom-button-det" block  @click="showInfoCT = !showInfoCT" >
      <div class="left-text">Codificadores de Ciencia y Tecnología</div>
      <div class="right-text">Detalles</div>
    </v-btn>
    
    <div v-if="showInfoCT">
      <br>
      <b>Requisitos:</b>
      <ul>
        <li>Título profesional, licenciatura o bachiller en educación con mención/especialidad en Ciencias Naturales, Biología y Química o afines</li>
        <li>Egresado o estudiante del último año de estas carreras o de una carrera afín al área de Ciencias Naturales. </li>
        <li>Experiencia docente en algún grado del nivel secundaria en el área de Ciencia y Tecnología o afines, o jefatura de práctica en universidades en las carreras profesionales señaladas en el punto anterior (deseable).</li>
        <li>Experiencia previa en procesos similares de codificación de pruebas estandarizadas de Ciencias (deseable). </li>
        <li>Disposición a tiempo completo durante las fechas de capacitación y codificación en los horarios estipulados. </li>
        <li>Manejo solvente del área de Ciencias (evaluación escrita)</li>
        <li>Capacidad de trabajo en equipo. </li>
        <li>Manejo de Office.</li>
      </ul>
      <br>
      <b>Cronograma:</b>
      <div>
        <v-btn class="mr-4" @click="openSchedule3" style="background-color: gainsboro;">Visualizar</v-btn>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-btn class="mr-4" color="success" @click="openDialog('Codificador de Ciencia y Tecnología')">Postular</v-btn>
      </div>
    </div>
  </div>

    <!--Perfil Ciencias Sociales-->
  <div class="elevation-12" style="padding: 15px;">
    <v-btn class="custom-button-det" block  @click="showInfoCS = !showInfoCS" >
      <div class="left-text">Codificadores de Ciencias Sociales</div>
      <div class="right-text">Detalles</div>
    </v-btn>
    
    <div v-if="showInfoCS">
      <br>
      <b>Requisitos:</b>
      <ul>
        <li>Título profesional, licenciatura o bachiller en educación secundaria con mención/especialidad en Historia y Geografía o en Ciencias Sociales (no Humanidades); o de las disciplinas de Historia o Geografía</li>
        <li>Experiencia docente en algún grado de secundaria en el área de Ciencias Sociales, o en jefatura de práctica en universidades en las carreras profesionales señaladas en el punto anterior (deseable).</li>
        <li>Experiencia previa en procesos similares de codificación de pruebas estandarizadas de Ciencias Sociales (deseable).</li>
        <li>Disposición a tiempo completo durante las fechas de capacitación y codificación en los horarios estipulados.</li>
        <li>Manejo disciplinar solvente del área de Ciencias Sociales y su enfoque (evaluación escrita y entrevista).</li>
        <li>Capacidad de trabajo en equipo (entrevista).</li>
        <li>Manejo de Office.</li>
      </ul>
      <br>
      <b>Cronograma:</b>
      <div>
        <v-btn class="mr-4" @click="openSchedule4" style="background-color: gainsboro;">Visualizar</v-btn>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-btn class="mr-4" color="success" @click="openDialog('Codificador de Ciencias Sociales')">Postular</v-btn>
      </div>
    </div>
  </div>

  </v-container>

</template>

<style>
.custom-button-det {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #007bff 80%, #4caf50 20%);
  color: white;
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.left-text {
  color: white;
}

.right-text {
  color: white;
  border-radius: 4px 0 0 4px;
}


.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #000;
  padding: 8px;
}

.large-font {
  font-size: 16px;
}

.conditions {
  margin-top: 20px;
}

</style>

<script>
  import {postulate} from '../service/service'
  import Swal from 'sweetalert2'
  export default {
    data: () => ({
      headers: [
        { text: "Actividad", value: "activity" },
        { text: "Fechas", value: "date" },
      ],
      activities: [
        { activity: "Recepción y evaluacion de CVs", date: "15/11/2023 al 12/12/2023" },
        { activity: "Publicación de pre-seleccionados", date: "13/12/2023 al 14/12/2023" },
        { activity: "Prueba escrita", date: "16/12/2023" },
        { activity: "Periodo de contratación", date: "08/01/2024 al 29/01/2024" },
        { activity: "Días de trabajo", date: "19 días" },
        { activity: "Lugar de trabajo", date: "Pacto andino 224 Chorrillos (referencia espalda de Tottus Defensores del Morro)" },
      ],
      activities1: [
        { activity1: "Recepción y evaluacion de CVs", date1: "15/11/2023 al 12/12/2023" },
        { activity1: "Publicación de pre-seleccionados", date1: "13/12/2023 al 14/12/2023" },
        { activity1: "Prueba escrita", date1: "16/12/2023" },
        { activity1: "Periodo de contratación", date1: "08/01/2024 al 29/01/2024" },
        { activity1: "Días de trabajo", date1: "17 días" },
        { activity1: "Lugar de trabajo", date1: "Pacto andino 224 Chorrillos (referencia espalda de Tottus Defensores del Morro)" },
      ],
      activities2: [
        { activity2: "Recepción y evaluacion de CVs", date2: "15/11/2023 al 12/12/2023" },
        { activity2: "Publicación de pre-seleccionados", date2: "13/12/2023 al 14/12/2023" },
        { activity2: "Prueba escrita", date2: "16/12/2023" },
        { activity2: "Periodo de contratación", date2: "12/01/2024 al 27/01/2024" },
        { activity2: "Días de trabajo", date2: "14 días" },
        { activity2: "Lugar de trabajo", date2: "Pacto andino 224 Chorrillos (referencia espalda de Tottus Defensores del Morro)" },
      ],
      activities3: [
        { activity3: "Recepción y evaluacion de CVs", date3: "15/11/2023 al 12/12/2023" },
        { activity3: "Publicación de pre-seleccionados", date3: "13/12/2023 al 14/12/2023" },
        { activity3: "Prueba escrita", date3: "16/12/2023" },
        { activity3: "Periodo de contratación", date3: "15/01/2024 al 23/01/2024" },
        { activity3: "Días de trabajo", date3: "8 días" },
        { activity3: "Lugar de trabajo", date3: "Pacto andino 224 Chorrillos (referencia espalda de Tottus Defensores del Morro)" },
      ],
      activities4: [
        { activity4: "Recepción y evaluacion de CVs", date4: "15/11/2023 al 12/12/2023" },
        { activity4: "Publicación de pre-seleccionados", date4: "13/12/2023 al 14/12/2023" },
        { activity4: "Prueba escrita", date4: "16/12/2023" },
        { activity4: "Periodo de contratación", date4: "11/01/2024 al 25/01/2024" },
        { activity4: "Días de trabajo", date4: "13 días" },
        { activity4: "Lugar de trabajo", date4: "Pacto andino 224 Chorrillos (referencia espalda de Tottus Defensores del Morro)" },
      ],

      valid: true,
      dni: null,
      dniRules: [
      v => !!v || 'Campo obligatorio',
      v => !(v && !/^\d{8}$/.test(v)) || 'El DNI debe tener 8 dígitos numéricos',
    ],
      names: '',
      lastNameFather: '',
      lastNameMother: '',
      stringRules: [
        v => !!v || 'Campo obligatorio',
        v => (v && v.length <= 100) || 'Debe tener menos de 100 caracteres',
      ],
      date: null,
      dateRules: [
        v => !!v || 'El campo es necesario',
      ],
      activePicker: null,
      menu: false,
      email: '',
      emailRules: [
        v => !!v || 'Campo obligatorio',
        v => /.+@.+\..+/.test(v) || 'Correo debe tener forma valida: ***@***.***',
      ],
      phone: '',
      phoneRules: [
        v => !(v && ! /^[0-9]{9}$/.test(v)) || 'El Telefono debe tener 9 digitos numéricos',
      ],
      cellphone: null,
      cellphoneRules: [
        v => !!v || 'Campo obligatorio',
        v => !(v && ! /^[0-9]{9}$/.test(v)) || 'El Celular debe tener 9 digitos numéricos',
      ],
      education: null,
      cv: null,
      cvRules: [
      v => !!v || 'Campo obligatorio'
      ],
      showInfoMate4P: false,
      showInfoMate2S: false,
      showInfoLec4P: false,
      showInfoLec2S: false,
      showInfoCT: false,
      showInfoCS: false,
      dialogForm: false,
      dialogSchedule: false,
      dialogSchedule1: false,
      dialogSchedule2: false,
      dialogSchedule3: false,
      dialogSchedule4: false,
      dialogPrueba:true,
      job:'',
    }),

    watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },

    methods: {
      openDialog(job) {
      this.dialogForm = true;
      this.job=job;
      },
      openSchedule() {
      this.dialogSchedule = true;
      },
      openSchedule1() {
      this.dialogSchedule1 = true;
      },
      openSchedule2() {
      this.dialogSchedule2 = true;
      },
      openSchedule3() {
      this.dialogSchedule3 = true;
      },
      openSchedule4() {
      this.dialogSchedule4 = true;
      },
      save (date) {
        this.$refs.menu.save(date)
      },
      reset () {
        this.$refs.form.reset()
      },
      validate () {
        let me = this
        if (this.$refs.form.validate()) {
          me.sendPostulate()
        }
      },
      async sendPostulate () {
        let me = this
        let form = {
          DNI: me.dni,
          Name: me.names,
          LastNameFather: me.lastNameFather,
          LastNameMother: me.lastNameMother,
          Birthdate: me.date,
          Email: me.email,
          Phone: me.phone,
          Cellphone: me.cellphone,
          Education: me.education,
          Assessment: '',
          Observation:'',
          Job: me.job,
        };
        let file = me.cv;
        
        await postulate(form,file).then(res => {
          if(res.success == true){
            if(res.data.message == "Datos guardados correctamente"){
              Swal.fire({
              icon: 'success',
              title: 'Postulación Exitosa!',
              showConfirmButton: false,
              timer: 1500
            })
              me.reset()
            }
          }else
            {
              Swal.fire({
                icon: 'error',
                title: 'Postulante ya registrado',
                showConfirmButton: false,
                timer: 1500
              })
            }
        })
    
      }
    },
  }
</script>