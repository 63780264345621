<template>
  <v-container fluid style="max-width: 100%; height: 100%; overflow-y: auto;">
<div style="overflow-x: auto;">
  <v-data-table
    :headers="headers"
    :items="filteredDesserts"
    sort-by="DNI"
    class="elevation-1"
  >
  <template v-slot:body.prepend>
          <tr>
            <td v-for="(header, i) in headers" :key="i">
              <v-text-field
                v-if="header.value !== 'actions'"
                v-model="columnFilters[header.value]"
                type="text"
                label="Buscar"
              ></v-text-field>
            </td>
          </tr>
        </template>

    <template v-slot:[`item.CV`]="{ item }">
      <a @click.prevent="downloadCV(item)" target="_blank">{{ item.CV }}</a>
    </template>

    <template v-slot:top>
        <v-dialog
          persistent
          v-model="dialog"
          max-width="650px"
        >
          <v-card>
            <v-card-title class="d-flex justify-center font-weight-bold">
              Calificación de postulante
            </v-card-title>
            <v-card-text>
              <v-container fluid style="max-height: 660px; overflow-y: auto;">
                <v-row align-content="center">
                  <v-col cols="6" >
                    <div><strong>DNI:</strong><br>{{ editedItem.DNI }}</div>
                    <div><strong>Nombres:</strong><br>{{ editedItem.NAME }}</div>
                    <div><strong>Apellido Paterno:</strong><br>{{ editedItem.LASTNAME_FATHER }}</div>
                    <div><strong>Apellido Materno:</strong><br>{{ editedItem.LASTNAME_MOTHER }}</div>
                    <div><strong>Fecha Nacimiento:</strong><br>{{ editedItem.BIRTHDATE }}</div>
                    <div><strong>Correo:</strong><br>{{ editedItem.EMAIL }}</div>
                    <div><strong>Teléfono:</strong><br>{{ editedItem.PHONE }}</div>
                    <div><strong>Celular:</strong><br>{{ editedItem.CELLPHONE }}</div>
                    <div><strong>Formación:</strong><br>{{ editedItem.EDUCATION }}</div>
                    <div><strong>CV:</strong><br>{{ editedItem.CV }}</div>
                  </v-col>

                <v-col cols="6" >
                  <div>
                    <strong>Egresado de alguna carrera profesional pertinente con el área a codificar</strong>
                    <v-select v-model="egresado" :items="['Si', 'No']"></v-select>
                  </div>
                  <div>
                    <strong>Experiencia docente en algún grado del nivel y área a evaluar</strong>
                    <v-select v-model="experienciaDocente" :items="['Si', 'No']"></v-select>
                  </div>
                  <div>
                    <strong>Experiencia previa en procesos similares de codificación de pruebas estandarizadas durante los tres últimos años</strong>
                    <v-select v-model="experienciaPrevia" :items="['Si', 'No']"></v-select>
                  </div>
                  <div>
                    <strong>Edad entre 22 y 50 años</strong>
                    <v-select v-model="edad" :items="['Si', 'No']"></v-select>
                  </div>
                  <div>
                    <strong>Evaluación Final:</strong>
                    <v-select v-model="evalucionFinal" :items="['Seleccionado', 'No seleccionado']"></v-select>
                  </div>
                  <div>
                    <strong>Observación:</strong>
                    <v-text-field v-model="observacion"></v-text-field>
                  </div>
                </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                color="blue darken-1" 
                text 
                @click="guardar"
              >
                 Guardar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="cancelar"
              >
                Cancel
              </v-btn>
            </v-card-actions>
            
          </v-card>
        </v-dialog>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
  <v-btn @click="viewItem(item)">Evaluar</v-btn>
</template>
    
    <!--<template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
 -->
  </v-data-table>
</div>
</v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import {getData, updatePostulant, downloadCV} from '../service/service';
  export default {
    data: () => ({
      dialog: false,
      editedItem: {},
      egresado : null,
      experienciaDocente : null,
      experienciaPrevia : null,
      edad : null,
      evalucionFinal : null,
      observacion : null,
      headers: [
      { text: 'DNI', value: 'DNI'},
      { text: 'Puesto', value: 'JOB'},
      { text: 'Nombres', value: 'NAME'},
      { text: 'Apellido Paterno', value: 'LASTNAME_FATHER'},
      { text: 'Apellido Materno', value: 'LASTNAME_MOTHER' },
      { text: 'Fecha Nacimiento', value: 'BIRTHDATE' },
      { text: 'Edad', value: 'AGE'},
      { text: 'Correo', value: 'EMAIL' },
      { text: 'Teléfono', value: 'PHONE'},
      { text: 'Celular', value: 'CELLPHONE' },
      { text: 'Educación', value: 'EDUCATION' },
      { text: 'CV', value: 'CV' },
      { text: 'Seleccionado para evaluación', value: 'ASSESSMENT' },
      { text: 'Observación', value: 'OBSERVATION' },
      { text: 'Evaluar', value: 'actions', sortable: false },
      ],
      desserts: [],
      defaultItem: {},
      columnFilters: {},
    }),

    computed: {
      ...mapState([
        'token'
      ]),
      filteredDesserts() {
      return this.desserts.filter((item) => {
        return this.headers.every((header) => {
          const filterText = this.columnFilters[header.value];
        
          if (filterText !== undefined) {
            const value = item[header.value];
          
            if (typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())) {
              return true;
            } else if (typeof value === 'number' && value.toString().includes(filterText)) {
              return true;
            }
            
            return false;
          }
        
          return true;
        });
      });
    },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      if (!this.token) {
      this.$router.push({name: 'login'});
      } else {
        this.initialize();
      }
    },

    methods: {
      async guardar(){
        const form = {
          DNI: this.editedItem.DNI,
          Assessment: this.evalucionFinal,
          Observation: this.observacion,
          Graduate: this.egresado,
          TeachingExperience: this.experienciaDocente,
          PreviousExperience: this.experienciaPrevia,
          Age: this.edad
        };

        const response = await updatePostulant(form);
        if (response.status){
          const data = await getData(this.token);
          if(data.status){
            this.desserts = data.table;
          }else {
          console.error('Error obtener data:', data.error);
          }
        }
        this.egresado = null;
        this.experienciaDocente = null;
        this.experienciaPrevia = null;
        this.edad = null;
        this.evalucionFinal = null;
        this.observacion = null;

        this.dialog = false;

      },
      cancelar(){
        this.egresado = null;
        this.experienciaDocente = null;
        this.experienciaPrevia = null;
        this.edad = null;
        this.evalucionFinal = null;
        this.observacion = null;

        this.dialog = false;
      },
      async downloadCV(item) {
        try {

          //const extension = item.CV.split('.').pop();
          const filename = `${item.CV}`;

          const response = await downloadCV(this.token, filename);

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      },

      async initialize() {
        const data = await getData(this.token);
        if(data.status){
          this.desserts = data.table;
        }else {
          console.error('Error obtener data:', data.error);
        }
  },

      viewItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
    },
  }
</script>