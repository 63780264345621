<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src=""
          transition="scale-transition"
          width="250"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-if="$store.state.token"
        @click= "logout"
        class="red darken-2 small" 
        dark
      >
        <v-icon left>mdi-exit-to-app</v-icon>
        Cerrar Sesión
        
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>


<script>
  import { mapState, mapMutations } from 'vuex';
export default {
  name: 'App',

  data: () => ({
    //
  }),

  computed: {
    ...mapState([
      'token'
    ])
  },

  methods: {
    ...mapMutations([
      'setToken'
    ]),
    logout(){
      let me = this;
      me.setToken('');
      me.$router.push({name:'login'})
    }
  }

};
</script>
