<template>
    <v-container style="width: 100%;">
  
      <v-dialog v-model="dialogForm" max-width="600">
        <v-card class="elevation-12" style="padding: 15px;">
        <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title class="d-flex justify-center font-weight-bold">
          Formulario de Postulación
        </v-card-title>
  
          <v-text-field
          v-model="dni"
          :rules="dniRules"
          :counter="8"
          label="DNI"
          required
          ></v-text-field>
  
          <v-text-field
            v-model="names"
            :counter="100"
            :rules="stringRules"
            label="Nombre"
            required
          ></v-text-field>
  
          <v-text-field
            v-model="lastNameFather"
            :counter="100"
            :rules="stringRules"
            label="Apellido Paterno"
            required
          ></v-text-field>
  
          <v-text-field
            v-model="lastNameMother"
            :counter="100"
            :rules="stringRules"
            label="Apellido Materno"
            required
          ></v-text-field>
  
          <v-menu 
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :rules="dateRules"
                label="Fecha de nacimiento"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date" 
              :active-picker.sync="activePicker"
              max="2001-12-30"
              min="1973-01-01"
              @change="save"
            ></v-date-picker>
          </v-menu>
  
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
  
          <v-text-field
            type="number"
            v-model.number="cellphone"
            :counter="9"
            :rules="cellphoneRules"
            label="Celular"
            required
          ></v-text-field>

          <v-text-field         
            v-model="phone"
            :counter="9"
            :rules="phoneRules"
            label="Telefono (OPCIONAL)"
          ></v-text-field>
  
          <v-text-field
            v-model="education"
            :counter="100"
            :rules="stringRules"
            label="Educación"
            required
          ></v-text-field>
  
          <v-file-input
            v-model="cv"
            show-size
            counter
            chips
            label="CV"
            :rules="cvRules"
            required
          ></v-file-input>
  
          <div class="d-flex justify-center">
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="validate"
          >
            ENVIAR
          </v-btn>
        </div>
        </v-form>
        </v-card>
      </v-dialog>
  
    <v-dialog v-model="dialogSchedule" max-width="900">
      <v-card>
        <v-card-title class="d-flex justify-center font-weight-bold">Cronograma del proceso</v-card-title>
        <v-card-text>
          <v-container>
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Actividad</th>
                  <th class="text-center">Fechas</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="activity in activities" :key="activity.activity">
                  <td><strong>{{ activity.activity }}</strong></td>
                  <td class="text-center"><strong>{{ activity.date }}</strong></td>
                </tr>
              </tbody>
            </table>
            <div class="conditions">  
            <b class="large-font">CONDICIONES DEL TRABAJO</b>
            <ul>
              <li><strong> Los supervisores serán seleccionados de acuerdo al puntaje obtenido en la prueba.</strong></li>
              <li><strong>Lunes a sábado: 8:15 a.m. a 4:00 p.m.</strong></li>
              <li><strong>Se brindará almuerzo a todo el personal.</strong></li>
            </ul>
            <br>
            <b class="large-font">REMUNERACIÓN</b>
            <ul>
              <li><strong>Supervisores: S/.6160.00 por proyecto</strong></li>
              <li><strong>Depuradores: S/.2600.00 por proyecto</strong></li>
            </ul>
            <br>
            <b class="large-font">Lugar de la prueba escrita: Se le comunicará al postulante preseleccionado.</b>
            
            
            
            
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  
    <div class="elevation-12" style="padding: 15px;">
      <v-btn class="custom-button-det" block  @click="showInfoMate = !showInfoMate" >
        <div class="left-text">DEPURADORES</div>
        <div class="right-text">Detalles</div>
      </v-btn>
      
      <div v-if="!showInfoMate">
        <br>
        <b>Requisitos:</b>
        <ul>
            <li>Egresado o estudiante de los últimos ciclos de las carreras de Estadística o Informática o Ingeniería de Sistemas o carreras afines.</li>
            <li>Experiencia previa en procesos similares de Depuración / Control de calidad de archivos con grandes volúmenes de datos (deseable).</li>
            <li>Disposición a tiempo completo durante las fechas de capacitación y depuración en los horarios estipulados.</li>
            <li>Capacidad de trabajo en equipo.</li>
            <li>Manejo de Office a nivel usuario.</li>
        </ul>
        <br>
        <b>Cronograma:</b>
        <div>
          <v-btn class="mr-4" @click="openSchedule" style="background-color: gainsboro;">Visualizar</v-btn>
        </div>
        <br>
        <div class="d-flex justify-center">
          <v-btn class="mr-4" color="success" @click="openDialog('Depurador')">Postular</v-btn>
        </div>
      </div>
    </div>
    
    </v-container>
  
  </template>
  
  <style>
  .custom-button-det {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, #007bff 80%, #4caf50 20%);
    color: white;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  
  .left-text {
    color: white;
  }
  
  .right-text {
    color: white;
    border-radius: 4px 0 0 4px;
  }
  
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    border: 1px solid #000;
    padding: 8px;
  }
  
  .large-font {
    font-size: 16px;
  }
  
  .conditions {
    margin-top: 20px;
  }
  
  </style>
  
  <script>
    import {postulate} from '../service/service'
    import Swal from 'sweetalert2'
    export default {
      data: () => ({
        headers: [
          { text: "Actividad", value: "activity" },
          { text: "Fechas", value: "date" },
        ],
        activities: [
          { activity: "Recepción y evaluacion de CVs", date: "13/11/2023 al 22/11/2023" },
          { activity: "Publicación de pre-seleccionados", date: "24/11/2023" },
          { activity: "Prueba escrita", date: "27/11/2023" },
          { activity: "Periodo de contratación", date: "Supervisores: 19/12/2023 al 07/02/2024 | Depuradores: 02/01/2024 al 31/01/2024" },
          { activity: "Lugar de trabajo", date: "Pacto andino 224 Chorrillos (referencia espalda de Tottus Defensores del Morro)" },
        ],
  
        valid: true,
        dni: null,
        dniRules: [
        v => !!v || 'Campo obligatorio',
        v => !(v && !/^\d{8}$/.test(v)) || 'El DNI debe tener 8 dígitos numéricos',
      ],
        names: '',
        lastNameFather: '',
        lastNameMother: '',
        stringRules: [
          v => !!v || 'Campo obligatorio',
          v => (v && v.length <= 100) || 'Debe tener menos de 100 caracteres',
        ],
        date: null,
        dateRules: [
          v => !!v || 'El campo es necesario',
        ],
        activePicker: null,
        menu: false,
        email: '',
        emailRules: [
          v => !!v || 'Campo obligatorio',
          v => /.+@.+\..+/.test(v) || 'Correo debe tener forma valida: ***@***.***',
        ],
        phone: '',
        phoneRules: [
          v => !(v && ! /^[0-9]{9}$/.test(v)) || 'El Telefono debe tener 9 digitos numéricos',
        ],
        cellphone: null,
        cellphoneRules: [
          v => !!v || 'Campo obligatorio',
          v => !(v && ! /^[0-9]{9}$/.test(v)) || 'El Celular debe tener 9 digitos numéricos',
        ],
        education: null,
        cv: null,
        cvRules: [
        v => !!v || 'Campo obligatorio'
        ],
        showInfoMate: false,
        showInfoLec: false,
        showInfoCT: false,
        showInfoCS: false,
        dialogForm: false,
        dialogSchedule: false,
        dialogPrueba:true,
        job:'',
      }),
  
      watch: {
        menu (val) {
          val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
      },
  
      methods: {
        openDialog(job) {
        this.dialogForm = true;
        this.job=job;
        },
        openSchedule() {
        this.dialogSchedule = true;
        },
        save (date) {
          this.$refs.menu.save(date)
        },
        reset () {
          this.$refs.form.reset()
        },
        validate () {
          let me = this
          if (this.$refs.form.validate()) {
            me.sendPostulate()
          }
        },
        async sendPostulate () {
          let me = this
          let form = {
            DNI: me.dni,
            Name: me.names,
            LastNameFather: me.lastNameFather,
            LastNameMother: me.lastNameMother,
            Birthdate: me.date,
            Email: me.email,
            Phone: me.phone,
            Cellphone: me.cellphone,
            Education: me.education,
            Assessment: '',
            Observation:'',
            Job: me.job,
          };
          let file = me.cv;
          
          await postulate(form,file).then(res => {
            if(res.success == true){
              if(res.data.message == "Datos guardados correctamente"){
                Swal.fire({
                icon: 'success',
                title: 'Postulación Exitosa!',
                showConfirmButton: false,
                timer: 1500
              })
                me.reset()
              }
            }else
              {
                Swal.fire({
                  icon: 'error',
                  title: 'Postulante ya registrado',
                  showConfirmButton: false,
                  timer: 1500
                })
              }
          })
      
        }
      },
    }
  </script>