import Vue from 'vue'
import VueRouter from 'vue-router'
import ViewDashboard from '../views/ViewDashboard.vue'
import ViewLogin from '../views/ViewLogin.vue'
import ViewPostulation from '../views/ViewPostulation.vue'
import ViewPostulation2 from '../views/ViewPostulation2.vue'


Vue.use(VueRouter)

const routes = [
  { path: '/codificadores', name: 'codificadores', component: ViewPostulation, meta: { title: 'Página de Postulación' } },
  { path: '/depuradores', name: 'depuradores', component: ViewPostulation2, meta: { title: 'Página de Postulación' } },
  { path: '/login',       name: 'login',       component: ViewLogin, meta: { title: 'Página de Inicio de Sesión' } },
  { path: '/dashboard',   name: 'dashboard',   component: ViewDashboard, meta: { title: 'Panel de Control' } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Configura una guarda de navegación para cambiar el título en función de la ruta actual
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'Mi Aplicación Vue.js';
  }
  next();
})

export default router
