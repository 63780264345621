<template>
        <v-container fluid fill-height>
           <v-layout align-center justify-center>
              <v-flex xs12 sm8 md4>
                 <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                       <v-toolbar-title>Iniciar sesión</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                    <form ref="form" @submit.prevent="login()">
                           <v-text-field
                             v-model="username"
                             name="username"
                             label="Correo"
                             type="text"
                             placeholder="Correo"
                             required
                          ></v-text-field>
                          
                           <v-text-field
                             v-model="password"
                             name="password"
                             label="Contraseña"
                             type="password"
                             placeholder="contraseña"
                             required
                          ></v-text-field>

                          <v-btn type="submit" class="mt-4" color="primary" value="log in">Entrar</v-btn>
                     </form>
                    </v-card-text>
                 </v-card>
              </v-flex>
           </v-layout>
        </v-container>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {login} from '../service/service'
import Swal from 'sweetalert2'
export default {
  name: "ViewLogin",
  data() {
    return {
      username: "",
      password: "",
      confirmPassword: "",
    };
  },
  computed: {
    ...mapState([
      'token'
    ])
  },
  methods: {
    ...mapMutations([
      'setToken'
    ]),
    async login() {
    try {
    const response = await login(this.username, this.password);
    if (response.success) {
      this.setToken(response.token);
      this.$router.push({ name: 'dashboard' });
    } else {
      Swal.fire({
                icon: 'error',
                title: 'Credenciales incorrectas. Por favor, inténtalo de nuevo.',
                showConfirmButton: false,
                timer: 1500
              })
    }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      Swal.fire({
                icon: 'error',
                title: 'Error al iniciar sesión. Por favor, inténtalo de nuevo.',
                showConfirmButton: false,
                timer: 1500
              })
    }

    }
  }
};
</script>