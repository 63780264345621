import axios from "axios";

let axiosInstance = axios.create({
	baseURL: "http://190.117.52.157:3000/",
	//baseURL: "http://localhost:3000/",
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Content-Type": "application/json",
	}
});

export { axiosInstance };