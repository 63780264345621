import store from "../store/index";
import {axiosInstance} from "./index";

export const login = async (username, password) => {
    let data = {
      status: false,
      token: '',
      error: '',
      success: false,
    };
    await axiosInstance.post('api/auth/login', {
        username,
        password
    }).then(async res => {
        data.status = true;
        data.token = res.data.token;
        data.success= res.data.success;
        store.commit('setToken', res.data.token);
        //console.log(data);
    }).catch(err => {
        console.log('hubo un error: ', err);
        data.status = false;
        data.success= false;
        data.error = err;
        console.log(data);
    });
    return data;
  }

export const postulate = async (form, file) => {
    let formData = new FormData();
    formData.append('DNI', form.DNI);
    formData.append('Name', form.Name);
    formData.append('LastNameFather', form.LastNameFather);
    formData.append('LastNameMother', form.LastNameMother);
    formData.append('Birthdate', form.Birthdate);
    formData.append('Email', form.Email);
    formData.append('Phone', form.Phone);
    formData.append('Cellphone', form.Cellphone);
    formData.append('Education', form.Education);
    formData.append('CV', file);
    formData.append('Assessment', '');
    formData.append('Observation', '');
    formData.append('Job', form.Job);

    let data = {
        status: false,
        error: '',
        success: false,
    };
    await axiosInstance.post('api/auth/postulant', formData,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(async res => {
        data.status = res.status;
        data.data = res.data;
        data.success= res.data.success;
        console.log(data);
    }).catch(err => {
        console.log('hubo un error: ', err);
        data.status = false;
        data.success= false;
        data.error = err;
        console.log(data);
    });
    return data;
}

export const getData = async (token) => {
    let data = {
        status: false,
        table: [],
        error: '',
        success: false,
    };

    await axiosInstance.post('api/auth/data', {token}).then(res => {
        data.status = true;
        data.table = res.data.records;
        data.success= res.data.success;
       // console.log(data);
    }).catch(err => {
        data.status = false;
        data.error = err;
        data.success= false;
        console.log(data);
    });
    return data;
}



export const updatePostulant = async (form) => {
    let data = {
      status: false,
      error: '',
      success: false,
    };
    await axiosInstance.post('api/auth/assessment', form).then(async res => {
      data.status = res.status;
      data.data = res.data;
      data.success= res.data.success;
      //console.log(data);
    }).catch(err => {
      console.log('hubo un error: ', err);
      data.status = false;
      data.error = err;
      data.success= false;
      console.log(data);
    });
    return data;
  }

export const downloadCV = async (token, cv) => {
  let data = {
    status: false,
    error: '',
    success: false,
    ruta: ''
  };
  await axiosInstance.post('api/auth/path', { token, CV: cv }, {
    responseType: 'blob'
  }).then(async res => {
    data.status = true;
    data.data = res.data;
    data.success= res.data.success;
    //console.log(data);
  }).catch(err => {
    console.log('hubo un error: ', err);
    data.status = false;
    data.error = err;
    data.success= false;
    console.log(data);
  });
  return data;
}